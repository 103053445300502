import { defineComponent } from 'vue'
import axios from 'axios'
import { AxiosResponse } from 'axios'

export interface Event {
  id: number
  category: string
  description: string
  date: string
  location: string
  organizer: string
  time: string
  title: string
}

const apiClient = axios.create({
  baseURL: 'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default defineComponent({
  getEvents(): Promise<AxiosResponse<Event>> {
    return apiClient.get('/events')
  },

  getEvent(id: number) {
    return apiClient.get('/events/' + id)
  }

  // async getFunctionalEvents(): Promise<any> {

  //   let data: Event[] = []
  //   let error: any = null

  //   await apiClient.get('/events')
  //   .then((resp: AxiosResponse<Event[]>) => {
  //     data = resp.data
  //     console.log('xresp.data', resp.data)
  //     return data
  //   })
  //   .catch(e => error = e)


  
  // }
})