<template>
<h1>Events for Good</h1>
  <div class="events">
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EventCard from '@/components/EventCard.vue' // @ is an alias to /src
// import axios from 'axios'
import EventService from '@/services/EventService'
import { Event } from '../services/EventService'
import { AxiosResponse } from 'axios'

export default defineComponent({
  name: 'EventList',
  components: {
    EventCard
  },
  data() {
    return {
      events: [] as Event[],
      error: null as any
    }
  },
  created() {
    EventService.getEvents()
      //.then((response: AxiosResponse<Array<Event>>) => {
      .then((response: AxiosResponse) => {
        this.events = response.data
      })
      .catch((error: any) => {
        console.log('error', error)
      })
    // [this.error, this.events] = EventService.getFunctionalEvents()
    // console.log('this.error', this.error)
    // console.log('this.events', this.events)
    // console.log('getFunction', EventService.getFunctionalEvents())
  }
})
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>