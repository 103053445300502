<template>
  <router-link :to="{ name: 'EventDetails', params: { id: event.id } }">
    <div class="event-card">
      <span>@ {{ event.time }} on {{ event.date }} </span>
      <h4>{{ event.title }}</h4>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PropType } from 'vue'

interface Event {
  id: number
  category: string
  title: string
  time: string
  date: string
}

export default defineComponent({
  name: 'EventCard',
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true
    }
  },
  data() {
    return {
      // event: {
      //   id: 5928101,
      //   category6: 'anmal welfare',
      //   title: 'Cat Adopyion Day',
      //   time: '12:00',
      //   date: 'January 28, 2022'
      // }
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}

.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
</style>
