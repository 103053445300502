
import { defineComponent } from 'vue'
import { PropType } from 'vue'

interface Event {
  id: number
  category: string
  title: string
  time: string
  date: string
}

export default defineComponent({
  name: 'EventCard',
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true
    }
  },
  data() {
    return {
      // event: {
      //   id: 5928101,
      //   category6: 'anmal welfare',
      //   title: 'Cat Adopyion Day',
      //   time: '12:00',
      //   date: 'January 28, 2022'
      // }
    }
  }
})
